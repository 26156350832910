<template>
  <div class="container">
    <v-container id="dashboard" fluid tag="section">

      <v-row>
        <v-col cols="12">
          <FeatureNotifications/>
          <CustomerDashboard v-if="currentUserRole === 'customer'" />
          <SupplierDashboard v-if="currentUserRole === 'supplier'" />
          <AccountManagerDashboard
            v-if="currentUserRole === 'account_manager'"
          />
          <SuperAdminDashboard v-if="currentUserRole === 'super_admin'" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import auth from "../../auth";
import * as conf from "../../conf.yml";
import axios from "axios";
import CustomerDashboard from "./CustomerDashboard";
import SupplierDashboard from "./SupplierDashboard";
import AccountManagerDashboard from "./AccountManagerDashboard";

import SuperAdminDashboard from "./SuperAdminDashboard.vue";

import FeatureNotifications from "./FeatureNotifications.vue";


export default {
  name: "Dashboard",
  data: () => ({
    currentUserRole: null,
  }),
  mounted() {
    if (!auth.user.userType || auth.user.userType === "user") {
      this.$router.push({ name: "CompleteProfile" });
    }
    this.currentUserRole = auth.user.userType;
  },
  components: {
    CustomerDashboard: CustomerDashboard,
    SupplierDashboard: SupplierDashboard,
    AccountManagerDashboard: AccountManagerDashboard,
    SuperAdminDashboard: SuperAdminDashboard,
    FeatureNotifications: FeatureNotifications

  },
};
</script>
