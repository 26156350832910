<template>
  <v-row class="md-layout md-gutter supplier-dashboard">
    <v-col
      cols="12"
      sm="6"
      md="6"
      lg="4"
      xl="3"
      v-for="item in items"
      :key="item.title"
    >
      <router-link :to="item.path">
        <div class="md-layout-item supplier-dashboard__item">
          <md-card class="card-style">
            <div class="icon-container-purple">
              <md-card-media md-medium class="media-content">
                <img :src="item.image" :alt="item.alt" />
              </md-card-media>
            </div>
            <md-card-content>
              <div class="title-style">{{ item.title }}</div>
              <div class="subtitle-style">{{ item.description }}</div>
            </md-card-content>
          </md-card>
        </div>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SupplierDashboard",
  data: () => ({
    items: [
      // {
      //   title: "My Inquiries",
      //   description: "Go to your inquiry list",
      //   path: "/app/inquiries/own?inquiry_type=own",
      //   image: require(`../../assets/dashboard-icons/inquiries.png`),
      //   alt: "Inquiry List",
      // },
      {
        title: "Collection List",
        description: "Go to your Collection list",
        path: "/app/collections",
        image: require(`../../assets/dashboard-icons/inquiries.png`),
        alt: "Collection List",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.supplier-dashboard {
  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    .title-style{
      font-weight: 700;
      font-size: 20px;
    }
    box-shadow: 0px 29px 15px rgba(169, 169, 194, 0.19);
  }

    .card-style{
    background: #FFFFFF;
    box-shadow: 0px 6px 11px rgba(187, 187, 219, 0.19);
    border-radius: 8px;
  }
  .icon-container-purple{
    background-color: #ebdaff;
    border-radius: 8px 8px 0px 0px;
  }

  .media-content{
    justify-content: center;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 40px;
  }

  .title-style{
    color: #473068;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
  }

  .subtitle-style{
    color: #473068;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}
</style>
